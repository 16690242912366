<template>
  <section>
    <v-row no-gutters>
      <v-col cols="12">
        <HeroAlt :hero-alt="heroAlt" />
        <p class="text-h6 text-uppercase font-weight-light text-center my-16">
          El Centro de Formación y Competencias IntegRHales no vende servicios o productos
          sino SOLUCIONES.
        </p>
      </v-col>
    </v-row>
    <v-container grid-list-md>
      <v-layout row wrap>
        <v-flex xs="12" sm="6">
          <v-card class="mx-auto" max-width="390px">
            <v-img
              class="white--text align-center mx-auto"
              height="200px"
              src="@/assets/serv-1.jpg"
            >
            </v-img>
            <v-card-title class="align-center"
              >Reclutamiento y selección <br />
              de personal</v-card-title
            >
            <!-- <v-card-subtitle class="pb-0"> Number 10 </v-card-subtitle>
            <v-card-text class="text--primary">
              <div>Whitehaven Beach</div>
              <div>Whitsunday Island, Whitsunday Islands</div>
            </v-card-text> -->
            <!-- <v-card-actions>
              <v-btn color="orange" text> Share </v-btn>

              <v-btn color="orange" text> Explore </v-btn>
            </v-card-actions> -->
          </v-card>
        </v-flex>
        <v-flex xl="12" sm="6">
          <v-card class="mx-auto" max-width="390px">
            <v-img
              class="white--text align-center mx-auto"
              height="200px"
              src="@/assets/serv-2.jpg"
            >
            </v-img>
            <v-card-title>Auditoría de <br />Recursos Humanos</v-card-title>
          </v-card>
        </v-flex>
        <v-flex xl="12" sm="6">
          <v-card class="mx-auto" max-width="390px">
            <v-img
              class="white--text align-center mx-auto"
              height="200px"
              src="@/assets/serv-3.jpg"
            >
            </v-img>
            <v-card-title>Asesoría integral en RH</v-card-title>
          </v-card>
        </v-flex>
        <v-flex xl="12" sm="6">
          <v-card class="mx-auto" max-width="390px">
            <v-img
              class="white--text align-center mx-auto"
              height="200px"
              src="@/assets/serv-4.jpg"
            >
            </v-img>
            <v-card-title
              >Certificación en consultoría<br />
              en Felicidad</v-card-title
            >
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-row no-gutters>
      <v-col cols="12">
        <Iso />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import HeroAlt from "@/components/sections/HeroAlt";
import Iso from "@/components/sections/Iso";
export default {
  components: {
    HeroAlt,
    Iso,
  },
  data() {
    return {
      heroAlt: [
        {
          src: "pexels-enter-1.jpg",
          heading: " Sector empresarial ",
        },
      ],
      show: false,
    };
  },
   mounted() {
    window.scrollTo(0, 0);
  },

  head() {
    return {
      title: "Servicios",
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "Infographic hypotheses influencer user experience Long madel ture gen-z paradigm shift client partner network product seilans solve management influencer analytics leverage virality. incubator seed round massmarket. buyer agile development growth hacking business-to-consumer ecosystem",
        },
      ],
    };
  },

  methods: {
    getImgUrl(img) {
      return require("@/assets/brands/" + img);
    },
  },
};
</script>
