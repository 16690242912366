<template>
  <section :class="$vuetify.theme.dark ? 'black' : 'white'" class="py-16">
    <v-container>
      <v-row>
        <v-col>
          <v-row no-gutters>
            <v-col class="text-center">
              <h2
                class="text-h4 text-md-h3 text-center font-weight-black text-capitalize mb-4"
              >
                Normatividad
              </h2>
              <br />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row class="mx-auto" style="max-width: 1200px">
        <v-col v-for="(plan, ix) in plans" :key="`plan-${ix}`" cols="12" md="6">
          <v-hover v-slot="{ hover }">
            <v-card
              :elevation="hover ? 24 : plan.elevation"
              :color="plan.color"
              max-width="400"
              :class="hover ? 'zoom' : 'notzoom'"
              class="mx-auto transition-swing"
            >
              <h3
                class="text-h4 text-center font-weight-black white--text pt-5"
                v-text="plan.plan"
              ></h3>
              <v-card-text
                class="text-center subtitle-1 white--text py-2"
                v-text="plan.description"
              ></v-card-text>
              <v-list>
                <v-list-item
                  v-for="(feature, ik) in plan.features"
                  :key="`feature-${ik}`"
                  dense
                >
                  <v-list-item-icon>
                    <v-icon>
                      {{ feature.icon }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-capitalize"
                      v-text="feature.text"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list> </v-card
          ></v-hover>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      plans: [
        {
          plan: 'Formación de Auditores Internos de RH',
          elevation: 16,
          color: 'indigo darken-1',
          description: 'Lo mejor para departamentos de RH',
          features: [
            {
              icon: 'mdi-web',
              text: 'ISO 19001',
            },
            {
              icon: 'mdi-harddisk',
              text: 'ISO/TC 260',
            },
          ],
        },
        {
          plan: 'Consultoria de RH basado en normas',
          elevation: 16,
          color: 'indigo darken-1',
          description: 'Lo mejor para empresas',
          features: [
            {
              icon: 'mdi-web',
              text: 'ISO 30400:2006',
            },
            {
              icon: 'mdi-harddisk',
              text: 'ISO 30401:2018',
            },
            {
              icon: 'mdi-signal',
              text: 'ISO 30405:2016',
            },
            {
              icon: 'mdi-account',
              text: 'ISO 30408:2016',
            },
            {
              icon: 'mdi-domain',
              text: 'ISO 30409:2016',
            },
            {
              icon: 'mdi-server',
              text: 'ISO 30414:2018',
            },
          ],
        },
      ],
    }
  },
}
</script>

<style scoped>
.zoom {
  transform: scale(1.025) translate(0, -10px);
  transition: transform 0.2s;
}
.notzoom {
  transition: transform 0.2s;
}
</style>
